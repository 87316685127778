import type { Interaction, InteractionMiddleware } from '@jeroen.bakker/just-attribute';

const snapChatAds: InteractionMiddleware = (currentInteraction: Interaction): Interaction => {
    // If it is already attributed to something just return that
    if (currentInteraction.source && currentInteraction.medium) {
        return currentInteraction;
    }

    const { ScCid, ...additionalParameters } = currentInteraction.parameters ?? {};

    if (!ScCid) {
        return currentInteraction;
    }

    const interaction: Interaction = {
        importantParameters: { ScCid },
        medium: 'cpc',
        source: 'snapchat',
    };

    if (Object.keys(additionalParameters).length > 0) {
        interaction.parameters = additionalParameters;
    }

    return interaction;
};

export default snapChatAds;
